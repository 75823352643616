import axios from 'axios';


const axiosInstance = axios.create({
    baseURL: 'https://friendare.com',
    timeout: 1000, // Optional: Set a timeout limit
    headers: {
        'Content-Type': 'application/json',
        'User-Type': 'panel'
    },
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // Handle errors here
        if (error.response) {
            console.error('Server Error:', error.response.data);
        } else if (error.request) {
            console.error('Network Error:', error.request);
        } else {
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;