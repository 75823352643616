import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-copy">Copyright 2024 @ Friendare</div>
            <div className="footer-contact">
                contact@friendare.com
            </div>
        </footer>
    );
}

export default Footer;
