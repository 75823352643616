import React, {useState} from 'react';
import './EmailForm.scss';
import {registerEmail} from '../../services/apiService';
import {toast} from 'react-toastify';

const EmailForm = () => {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            await registerEmail(email);
            setIsSubmitted(true);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Internal Server Error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form className={`email-form ${isSubmitted ? 'submitted' : ''}`} onSubmit={handleSubmit}>
            {!isSubmitted ? (
                <>
                    <input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="email-input"
                        required
                        disabled={loading}
                    />
                    <button type="submit" className="submit-button" disabled={loading}>
                        {loading ? 'Submitting...' : <span>➤</span>}
                    </button>
                </>
            ) : (
                <div className="joined-message">You joined successfully!</div>
            )}
        </form>
    );
};

export default EmailForm;
