import Navbar from './components/Navbar/Navbar';
import MainContent from './components/landing/Main';
import Footer from './components/Footer/Footer';
import './App.css';

function App() {
    return (
        <div className="App">
            <Navbar/>
            <MainContent/>
            <Footer/>
        </div>
    );
}

export default App;
