import React from 'react';
import './MainContent.scss';
import EmailForm from '../EmailForm/Email';
import Photo from '../../photo.svg';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function MainContent() {
    return (
        <div className="main-content">
            <div className="text-container">
                <h1>Something amazing is in the making!</h1>
                <p>Do you like to know more?</p>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000} // Toast will disappear after 5 seconds
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <EmailForm/>
            </div>
            <div className="image-container">
                <img src={Photo} alt="Main Content"/>
            </div>
        </div>
    )
}

export default MainContent;
