import React from 'react';
import './Navbar.scss';
import Logo from '../../logo.svg';

function Navbar() {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={Logo} alt="Friendare Logo" width="48" height="48"/>
                <span className="navbar-brand">Friendare</span>
            </div>
        </nav>
    );
}

export default Navbar;
